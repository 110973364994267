/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function () {
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');

                // Toggle
                // Navigation
                $('.js-toggle-nav').click(function (e) {
                    e.preventDefault();

                    $('.c-nav--primary').toggleClass('is-open');
                });

                // Dropdown(s)
                $('.js-toggle-dropdown').click(function () {
                    $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
                });

                // Read More
                if ($('.js-more').length) {
                    $('.js-more').click(function () {
                        var btnTxt = $('.c-section__more').is(':visible') ? 'Read More' : 'Read Less';

                        $(this).text(btnTxt);

                        $(this).prev('.c-section__more').slideToggle();
                    });
                }

                // Scroll Up
                if ($('.js-scroll-up').length) {
                    var scollUp = $('.js-scroll-up');

                    $(window).scroll(function () {
                        var scrollY = $(this).scrollTop();

                        if (scrollY > 500) {
                            scollUp.addClass('is-visible');
                        } else {
                            scollUp.removeClass('is-visible');
                        }
                    });

                    scollUp.click(function (e) {
                        e.preventDefault();

                        $('html, body').animate({
                            scrollTop: 0
                        }, 1000);
                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                var viewportWidth = $(window).width();

                // Column background
                if ($('.o-col--background').length) {
                    var background;

                    $('.o-col--background').each(function () {
                        if ($(this).data('background')) {
                            background = $(this).data('background');
                        } else {
                            background = '/app/uploads/2018/02/w960x680.png';
                        }

                        $(this).css('background-image', 'url(' + background + ')');
                    });
                }

                // Section background
                $('.c-section--background-full').each(function () {
                    var elSection = $(this),
                        mobileBG = elSection.data('mobile'),
                        desktopBG = elSection.data('desktop');

                    elSection.each(function () {
                        if (viewportWidth < 1024) {
                            elSection.css('background-image', 'url(' + mobileBG + ')');
                        } else {
                            elSection.css('background-image', 'url(' + desktopBG + ')');
                        }
                    });
                });

                // Google Map
                var map,
                    geocoder = new google.maps.Geocoder(),
                    infowindow = new google.maps.InfoWindow(),
                    coordinates = [37.9792354, -121.473801];

                function initialize() {
                    var latLng = new google.maps.LatLng(coordinates[0], coordinates[1]);

                    var map = new google.maps.Map(document.getElementById('map-canvas'), {
                        disableDefaultUI: true,
                        draggable: true,
                        scrollwheel: false,
                        zoom: 15,
                        zoomControl: true,
                        center: latLng,
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    });

                    geocoder.geocode({ "latLng": latLng }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            google.maps.event.addDomListener(window, 'resize', function () {
                                map.setCenter(results[0].geometry.location);
                            });

                            var marker = new google.maps.Marker({
                                map: map,
                                position: results[0].geometry.location
                            });

                            /*

                            var content = "<h6>Teamsters Local 572</h6>" +
                                "<p>" + results[0].formatted_address + "</p>";

                            infowindow.setContent(content);

                            google.maps.event.addListener(marker, 'click', function () {
                                infowindow.open(map, marker);
                            });

                            */
                        }
                    });
                }
                google.maps.event.addDomListener(window, 'load', initialize);
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
